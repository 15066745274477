export interface DefaultCategories {
  slug: string;
  img: string;
  isAuth?: boolean;
}

export interface UserBalances {
  currency: string;
  balance: number;
  bonusBalance: number;
  points?: number;
}

export interface UserPaymentVerificationStatus {
  validPaymentsMethods: PaymentsVerification[];
  notValidPaymentsMethods: PaymentsVerification[];
  paymentSystemSafetiness: boolean;
}

export interface PaymentsVerification {
  name: string;
  payer: string;
}
export interface MerchantList {
  id: string;
  name: string;
  weight: number;
  menuId: string;
  alias: string;
  image: string;
  merchantCurrencies: MerchantCurrencies[];
  imageLogo?: string;
}

export interface MerchantCurrencies {
  name: string;
}

export interface CategoryList {
  cSort: number;
  cSubSort: number;
  categoryTags: Array<CategoryTags>;
  slug: string;
  text: Text;
}
export interface Game {
  id: string;
  hasDemo: boolean;
  merchant: Merchant;
  image?: string;
  imageFullPath: string;
  isVirtual: boolean;
  text: Text;
  pageCodes: PageCodes;
  merchantId: number;
  parentMerchantId: number;
  isFavorite?: boolean;
  Likes: { gameId?: string; likes: number };
  gameCategories?: Array<any>;
  bonusRestricted?: boolean;
  tableID: string;
}

export interface Text {
  name_en: string;
}
export interface CategoryTags {
  name: string;
}
export interface PageCodes {
  launch: string | null;
  mobileUrl: string | null;
  url: string | null;
  default: string | null;
  external: string | null;
  mobileExternal: string | null;
  mobileAndroid: string | null;
  mobileWindows: string | null;
}

export interface LinkButton {
  name: string;
  url: string;
  tab?: boolean;
  mainLink?: boolean;
  size?: string;
}

export interface SecondMenuButton {
  link: string;
  name: string;
  description?: string;
  icon?: string;
  amount?: string;
  isAuth?: boolean;
  isActive?: boolean;
  type?: 'primary' | 'secondary';
}

export interface Merchant {
  name: string;
  alias: string;
}
export interface toasterMessage {
  title?: string;
  message?: string;
  type: 'success' | 'error' | 'warning' | 'info';
  img?: string;
  url?: string;
  place: string;
  isLoaded?: boolean;
}

export interface activeWithdrawal {
  ID: string;
  System: string;
  Amount: string;
  AmountEur: string;
  Status: number;
  Note: string;
  Date: string;
  DateISO: string;
  AllowCancelation: string;
  Currency: string;
  Wallet: string;
}

export interface feeTypografy {
  symbol: string;
  text: string;
}

export interface SliderJson {
  imgage: string;
  slug: string;
  menuId: string;
}
export interface feeSumCounted {
  initialAmount: number;
  sum: number;
  percent: number;
  fix: number;
}

export interface feeCheck {
  fixed: number;
  percentage: number;
  round: 'up' | 'down' | 'round';
}
export interface feeCount {
  type: 'deposit' | 'withdraw';
  amount: number;
  round: 'up' | 'down' | 'round';
  fix: number;
  percent: number;
}

export interface withdrawDescription {
  control: string;
  labelWithoutTranslation?: string;
}

export interface infoLine {
  name: string;
  description: string;
  isBalance?: boolean;
  userCurrency?: string;
  icon?: string;
  amount?: string;
}

export interface UserProfile {
  idUser?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  login?: string;
  currency?: string;
  birthDay?: string;
  birthMonth?: string;
  birthYear?: string;
  gender?: string;
  extProfile: extProfile;
  countryCode?: string;
  city?: string;
  address?: string;
  postalCode?: string;
  phoneNumber?: string;
  phoneCode?: string;
  currentPassword?: string;
  bankName?: string;
  branchCode?: string;
  swift?: string;
  stateCode?: string;
  ibanNumber?: string;
  fingerprint?: string;
  registrationPromoCode?: string;
}
export interface extProfile {
  pep?: boolean;
  state?: string;
  dontSendSms?: boolean;
  dontSendEmail?: boolean | string;
  sendSMS?: boolean;
}
export interface messageInterface {
  ID: string;
  Personal: string;
  Status: string;
  Content: string;
  Name: string;
  Created: string;
  IsEvent: string;
  date?: string;
}
export interface messageParsed {
  date: string;
  icon: string;
  message: string;
  title: string;
}
export interface lastWithdrawals {
  email?: string;
  amount?: string;
  time?: WithdrawalsData;
  method?: WithdrawalsData;
  status?: WithdrawalsData;
}
export interface WithdrawalsData {
  title?: string;
  value: any;
  img?: string;
}
export interface withdrawalsInterface {
  name: string;
  method: WithdrawalsData;
  amount: string;
  status: any;
  time: string;
}
export interface providrsList {
  bannerImg: string;
  logoImg: string;
  id: string;
}
export interface GendersInterface {
  value: string;
  key: string;
  touched?: boolean;
  img: string;
}
export interface PepInterface {
  value: string;
  key: string;
  invalid?: boolean;
}
export interface notificationsInterface {
  name: string;
  checked: boolean;
  formKey?: string;
  img?: string;
}
export interface dateValues {
  selectedValue?: boolean;
  Year?: any;
  Month?: any;
  Day?: any;
}

export interface datekeys {
  boxKey: string;
  formKey: string;
}
export interface bonusDesc {
  name: string;
  img: string;
  subtext: string;
}
export interface bonusList {
  bonusDesc: bonusDesc;
  readMe: boolean;
}
export interface keystonePage {
  id?: string;
  description?: string;
  isVisible?: boolean;
  name?: string;
  opengraphDescription?: string;
  opengraphImage?: string;
  opengraphKeywords?: string;
  opengraphTitle?: string;
  slug?: string;
  buttonClass?: string;
  buttonAction?: string;
  short?: string;
  img?: string;
}
export interface FAQResponse {
  FAQs: Array<FAQ>;
  order: number;
  slug: string;
  title: string;
}

export interface FAQ {
  description: string;
  slug: string;
  title: string;
}

export interface termsVersions {
  title: string;
  Name: string;
}
export interface hardcodedCategories {
  urlFirst: string;
  urlSecond: string;
  name: string;
  slug: string;
  isAuth?: boolean;
}
export interface selectBoxItem {
  value: string;
  key: string | boolean;
  invalid?: boolean;
  img: string;
}
export interface countryInterface {
  value?: string;
  iso3?: string;
  iso2?: string;
  title?: string;
  phoneCode?: string;
}
export interface currencyInterface {
  value: string;
  title: string;
  ID: string;
  Name: string;
  Exrate: string;
}

export interface GeoCurrency {
  excludeCurrencies: string[];
  priorCurrencies: string[];
  slug: string;
}

export interface ResponseGeoCurrency {
  allGeoCurrencies: {
    excludeCurrencies: string;
    priorCurrencies: string;
    slug: string;
  }[];
}

export interface dateInterface {
  day: number;
  month: number;
  year: number;
}
export interface monthInterface {
  month: string;
  days: number;
  date: string;
}
export interface luckyPlayer {
  ID?: any;
  Email: string;
  Amount: string;
  Currency: string;
  Game: string;
  Image: string;
  GameID: string;
}
export interface selectedDate {
  month: selectedMonth;
  day: selectedDay;
  selected: boolean;
}
export interface selectedDay {
  day: number;
  variant?: string;
  stopSelect?: boolean;
}
export interface selectedMonth {
  year: number;
  month: string;
  monthDate: string;
  days: Array<selectedDay>;
}
export interface DateColection {
  selectedMonthLeftPage: number;
  selectedMonthRightPage: number;
  selectedFinalDate: selectedDate;
  selectedInitialDate: selectedDate;
  activeMonths: Array<selectedMonth>;
  item: selectedDay;
  selectedMonthLeft?: any;
  selectedMonthRight?: any;
}

export interface formGeneratorData {
  variant?: string;
  placeholder?: string;
  label?: string;
  items?: Array<any>;
  attention?: boolean;
  isDisabled?: boolean;
  value?: string;
  values?: dateValues;
  isFull?: boolean;
  isLight?: boolean;
  formControlName?: string | Array<string>;
  img?: string;
  isProfile?: boolean;
  style?: string;
  isConfirmNewPassword?: boolean;
  isCorrect?: boolean;
  error?: object;
  validators?: Array<any>;
  type?: string;
}

export interface possibleLang {
  code?: string;
  lable?: string;
}
export interface shadowHeader {
  main: boolean;
  header: boolean;
}
export interface shopFilterOptions {
  Name?: string;
  isSelected?: boolean;
  slug?: string;
}
export interface transactionLineInterface {
  ID: number;
  System: string;
  Amount: number;
  AmountEur: number;
  Currency: string;
  Status: number;
  Note: string;
  Date: string;
  DateParsed?: string;
  DateISO: string;
}
export interface Feature {
  imageSrc: string;
  mainText: string;
  additionalText?: string;
  extraText?: string;
}

export interface notificationInterface {
  active: boolean;
  message: string;
  isSuccess?: boolean;
  isError?: boolean;
}
export interface inputs {
  days: string;
}

export interface Payout {
  time: string;
  money: string;
}

export interface gameToLaunch {
  id: string;
  category: string;
  demo: '1' | '0';
}
export const currencyCountryMap: {
  [key: string]: string;
} = {
  EUR: 'eun',
  RUB: 'rus',
  USD: 'usa',
  NOK: 'nor',
  CAD: 'can',
  TRY: 'tur',
  BRL: 'bra',
  MXN: 'mex',
  AUD: 'aus',
};
export interface NavItems {
  name: string;
  url: string;
  variant: 'primary' | 'secondary' | 'tertiary';
  svg?: string;
  selectedSvg?: string;
  isActive?: boolean;
}
export interface selectedDepositData {
  currency?: any;
  amount?: number;
}
export interface stepperData {
  step: number;
  isBonus: boolean;
  selectedPayment?: any;
}
export interface CashBackCard {
  amount: number;
  nextAvailableAt: string;
  availableUntil: string;
  isTaken: boolean;
  type: number;
  info?: string;
  userId: string;
}
export interface Ranks {
  claimAmount: number;
  id: number;
  rankid?: number;
  name: string;
  turnoverAmount: number;
  isAvailable?: boolean;
  progress?: number;
  isClaimed?: boolean;
  color?: string;
}
export interface Rank {
  rankid?: number;
  name: string;
  rankName?: string;
  turnover?: number;
  progress?: number;
}
export interface calendarDay {
  day: number;
  fullAmount?: number;
  claimedAmount?: number;
  readyToClaimAmount?: number;
  readyToClaim?: boolean;
  monthVariant?: string;
  variant?: string;
  actions: any[];
  collectPreviousDay?: boolean;
  pendingSum?: number;
  isSelected?: boolean;
  informationText?: Array<calendarTooltipText>;
}
export interface calendarMonth {
  year: number;
  month: string;
  monthDate: string;
  days: Array<calendarDay>;
}
export interface calendarAction {
  id: number;
  userId: string;
  amount: number;
  type: string;
  status: string;
  startDateOfUsage: string;
  expirationDate: string;
}
export interface userRewardedStatistics {
  id?: number;
  userId?: string;
  totalRewarded: number;
  rakeBalanceRewarded: number;
  rankRewarded: number;
  calendarRewarded: number;
  cashbackRewarded: number;
  welleBetBalanceRewarded: number;
}
export interface tradableBalance {
  id?: number;
  userId?: string;
  balance: number;
}
export interface reefbackWellbet {
  name: string;
  about: string;
  balance: number;
  isTaken: boolean;
  nextAvailableAt: string;
  isAcardionOpened?: boolean;
  img: string;
  wellBetBtns: { img?: string; text: string; percents: string; amount?: string; level?: boolean }[];
}
export interface loyaltyInfoMenu {
  name: string;
  balance: number;
  progress?: number;
}
export interface calendarTooltipText {
  name: string;
  sum: number;
}
export interface userTurnover {
  id: number;
  userId: string;
  balance: number;
}
export interface SuccessItemPurchase {
  userId: string;
  itemId: number;
  bonusId: string[];
}
export interface FavoriteResponse {
  favorite: number;
  game_id: string;
}

export interface PaymentMethods {
  name: string;
  image: string;
  depositMethod: boolean;
  depositLimit?: string;
  withdrawMethod: boolean;
  withdrawLimit?: string;
  depositFee: boolean;
  country: string;
}

export const languages: any[] = [
  {
    code: 'en',
    label: 'English',
  },
  {
    code: 'ru',
    label: 'Русский',
  },
  {
    code: 'fr',
    label: 'Français',
  },
  {
    code: 'de',
    label: 'Deutsch',
  },
  {
    code: 'bg',
    label: 'Български',
  },
  {
    code: 'da',
    label: 'Dansk',
  },
  {
    code: 'es',
    label: 'Español',
  },
  {
    code: 'fi',
    label: 'Suomi',
  },
  {
    code: 'gr',
    label: 'ελληνικά',
  },
  {
    code: 'it',
    label: 'Italiano',
  },
  {
    code: 'no',
    label: 'Norsk',
  },
  {
    code: 'pl',
    label: 'Polski',
  },
  {
    code: 'sv',
    label: 'Svenska',
  },
  {
    code: 'hi',
    label: 'हिन्दी',
  },
  {
    code: 'pt',
    label: 'Português',
  },
];

export interface LimitPeriod {
  skip_unit: TimeUnit;
  skip_by_unit: number;
  take_unit: TimeUnit;
  take_by_unit: number;
  start_point: PointOfUnit;
  start_date_unit: TimeUnit;
  end_point: PointOfUnit;
  end_date_unit: TimeUnit;
  type: TypeLimit;
}

export enum TypeLimit {
  FROM_NOW = 'FROM_NOW',
  DATE_TO_DATE = 'DATE_TO_DATE',
}

export enum ResultDate {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}
export enum PointOfUnit {
  START = 'start',
  END = 'end',
}
export enum TimeUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
export interface bannerBankWidgetItem {
  img: string;
  name: string;
  quantity: number;
  url: string;
}
export interface SpineItem {
  width?: number;
  height?: number;
  atlas: string;
  json: string;
  currentAnimation: string;
  onClickAnimation?: string;
  playAnimOnce?: boolean;
  nextAnimation?: string;
  fallbackImage?: string;
}
export interface RateRelations {
  id: number;
  percentFromAllAmount: number;
  constAmount: number;
}

export interface BankRate {
  id: number;
  userConditionsId: number;
  weight: number;
  timePeriodInMilliseconds: string;
  rateId: number;
  rateRelations: RateRelations;
}

export interface ActiveTimePeriod {
  startDate: string;
  endDate: string;
}

export interface Stack {
  id: number;
  userId: string;
  bankRateId: number;
  moneyStackAmount: number;
  status: number;
  dateOfCreate: string;
  stackAmount: number;
  dateWhenNeedToRefresh: string;
  activeTimePeriods: ActiveTimePeriod[];
  bankRate: BankRate;
}

export interface BankRateData {
  rateRelations: RateRelations;
  bankRate: BankRate;
  stacks: Stack[];
  moneyStackAllAmount: number;
  stackedAmount: number;
  refreshDate: string;
}
export interface RateRelations {
  id: number;
  percentFromAllAmount: number;
  constAmount: number;
}

export interface BankLimits {
  id: number;
  userConditionsId: number;
  weight: number;
  timePeriodInMilliseconds: string;
  rateId: number;
  userConditionsRelation: UserConditionsRelation;
  rateRelations: RateRelations;
  rank: string;
}

export interface UserConditionsRelation {
  id: number;
  userTags: string[];
  limitsId: number;
  turnover: number;
  limitsRelation: LimitsRelation;
  userRanks: string[];
  description: string;
}

export interface LimitsRelation {
  id: number;
  lte: number;
  gte: number;
}

export interface RateRelations {
  id: number;
  percentFromAllAmount: number;
  constAmount: number;
}
export interface ReadyToClaimQuest {
  readyToClaimCount: number;
  readyToClaimAuthQuest: boolean;
}
export interface RegistrationSwitcher {
  fullRegistration: boolean;
  affiliates: string;
  exceptionCountries: Array<{ name: string }>;
}
export interface CustomCashback {
  amount: number;
  isAvailable: boolean;
}
